export default {
    treatments: {
        description: "Prises en charge",
        create: false,
        read: false,
        update: false,
        delete: false,
    },
    sales: {
        description: "Devis & Ventes",
        create: false,
        read: false,
        update: false,
        delete: false,
    },
    invoices: {
        description: "Factures",
        create: false,
        read: false,
        update: false,
        delete: null,
    },
    customers: {
        description: "Clients",
        create: false,
        read: false,
        update: false,
        delete: false,
    },
    devices: {
        description: "Appareils", // Types, marques, catégories, modèles
        create: false,
        read: false,
        update: false,
        delete: false,
    },
    products: {
        description: "Produits",
        create: false,
        read: false,
        update: false,
        delete: false,
    },
    stockEntries: {
        description: "Entrées en stock",
        create: false,
        read: false,
        update: false,
        delete: false,
    },
    services: {
        description: "Prestations",
        create: false,
        read: false,
        update: false,
        delete: false,
    },
    company: {
        description: "Paramètre entreprise",
        create: null,
        read: false,
        update: false,
        delete: null,
    },
    users: {
        description: "Utilisateurs",
        create: false,
        read: false,
        update: false,
        delete: false,
    },
    roles: {
        description: "Roles",
        create: false,
        read: false,
        update: false,
        delete: false,
    },
};